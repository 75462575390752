<template>
	<div>
    <Loader v-if="!eventInfo || !eventInfo.id" />
    <div v-if="eventInfo.id">
      <form ref="form" @submit.prevent>

      	<div class="dashboard__container--body" v-if="eventInfo">
 
            
            <div class="dashboard__container--body--col" style="width:100%;">
              <div class="mb-3">
              <div class="flex justify-space-between align-center">
                <h4>Jobs to Staff</h4>
                
              </div>
              
              <div class="mb-3">
                <label for="pickJobs">Choose Jobs:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="jobs"
                  v-model="event.jobs"
                  multiple
                  taggable
                  push-tags
                  >
                </v-select>
              </div>
              <transition name="fadeStop">
                <div class="mb-5" v-if="event.jobs && event.jobs.length >= 1">
                  <div v-for="job in event.jobs" class="mb-3 flex justify-start flex-row flex-wrap">
                    <div style="width:calc(25% - 1rem); min-width:80px;">
                      <label for="job">Job Title</label>
                      <input type="text" readonly v-model.trim="job.title" />
                    </div>
                    <div class="ml-3" style="width:calc(25% - 1rem); min-width:80px;">
                      <label for="pay">Pay Rate</label>
                      <input type="number" step=".01" placeholder="pay rate" v-model.trim="job.rate" required />
                    </div>
                    <div class="ml-3" style="width:calc(25% - 1rem); min-width:80px;">
                      <label for="bill">Bill Rate</label>
                      <input type="number" step=".01" placeholder="bill rate" v-model.trim="job.billRate" />
                    </div>
                    <div class="ml-3" style="width:calc(25% - 1rem); min-width:120px;">
                      <label for="cert">Required Certs</label>
                      <v-select
                        class="mt-2"
                        label="title" 
                        :options="certs"
                        v-model="job.certs"
                        :clearable="true"
                         multiple
                        >
                      </v-select>
                    </div>
                  </div>
                </div>
              </transition>

              
              
            </div>
          </div>

        </div>
       
        <div class="dashboard__container--body">
          
    

          <div class="floating_buttons">
            <button class="btn btn__success btn__large" @click="updateEvent()" :disabled="event.status == 'cancelled'">
            Update Order
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>

      	</div>
      </form>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../../firebaseConfig.js')

export default {
  props: ['eventInfo'],
  name: 'orderJobs',
  data: () => ({
    job: '',
    performingRequest: false,
  }),
  components: {
    Loader
  },
  async mounted() {
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
    if (!this.certs || this.certs.length < 1) {
      this.$store.dispatch("getCerts")
    }
    
  },
  computed: {
    ...mapState(['currentUser', 'jobs','certs']),
    event() {
      return this.eventInfo
    },
    jobss() {
      return this.jobs
    },
  },
  methods: {

    addJob() {
      let event = this.event
      event.jobs.push(this.job)
      this.job = ''
      this.$store.dispatch('updateEvent', event)
    },

    deleteJob(index) {
      let event = this.event
      event.jobs.splice(index, 1)
      this.$store.dispatch('updateEvent', event)
    },
    updateEvent() {
      this.performingRequest = true
    	let event = this.event
      // let croppa = this.croppa
      let store = this.$store

        console.log(event)
        this.$store.dispatch('updateEvent', event)
        setTimeout(() => {
          // croppa.remove()
          this.performingRequest = false
          // let url = `/orders`
          // router.push(url)
        }, 2000)
    }
  },

  beforeDestroy () {
    this.$store.dispatch('clearJobsState')
    this.$store.dispatch('clearCerts')
    this.job = null
    this.performingRequest = null
    delete this.performingRequest
    delete this.job
  },
  destroyed() {
    console.log(this)
  }
}
</script>